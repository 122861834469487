//中文包语言
module.exports = {
  login: {
    title: "在线考试系统",
    accountTitle: "账号登录",
    username: "用户名",
    password: "密码",
    login: "登录",
    register: "注册",
    usernameplaholder: "请输入用户名",
    passwordplaholder: "请输入密码",
    emptymessage: "请填写用户名或密码",
  },
  register: {
    username: "用户名",
    country: "国家",
    tel: "电话号码",
    email: "邮箱",
    pwd: "密码",
    create: "创建",
    empty: "重置",
    usernameplaholder: "请输入用户名",
    countryplaholder: "请选择国家",
    telplaholder: "请输入电话号码",
    emailplaholder: "请输入邮箱",
    passwordplaholder: "请输入密码",
    pwdnotempty: "密码不能为空",
    pwdlimuit: "密码不能小于6位",
    telnotempty: "电话号码不能为空",
    tellimit: "请输入正确的格式并且长度在6-14",
    emaillimit: "请输入正确的邮箱格式",
    namenotempty: "请输入用户名",
    namelimit: "请输入正确的格式并且长度在3-10",
  },
  resetpwd: {
    email: "邮箱",
    emailplaholder: "请输入邮箱",
    Code: "验证码",
    codeplaholder: "请输入验证码",
    password: "新密码",
    surepassword: "确认密码",
  },
  header: {
    titleone: "在线考试",
    titletwo: "练习列表",
    titlethree: "分数排行",
    titlefour: "历史分数",
    accountmanger: "修改密码",
    logout: "退出",
  },
  manger: {
    title: "修改密码",
    password: "密码",
    surepassword: "确认密码",
    handel: "提交",
    reset: "重置",
    passempty: "密码不能为空",
    passwordlimit: "密码不能小于6位",
    pwdagain: "请再次输入密码",
    twopwdnotsame: "两次输入密码不一致!",
  },
  startexam: {
    limit: "限时",
    time: "分钟",
    fulltitle: "满分",
    searchplaholder: "输入试卷标题",
    search: "搜索",
    // imputplaceholder: "",
  },
  scoreTable: {
    score: "分数",
    name: "姓名",
    connect: "联系方式",
    email: "邮箱",
    date: "考试日期",
  },
  answer: {
    tips: "考试结束时间未到,是否提前交卷",
    friendlytips: "友情提示",
    nowup: "立即交卷",
    check: "在检查一下",
    title: "请选择正确的选项",
    deragoustips: "请专心答题，不要进行其他违规操作，若在出现",
    time: "次",
    many: "则会默认提交试卷",
    dailogtitle: "注意",
    username: "姓名",
    id: "准考证号",
    routerMessage: "此操作将退出考试, 是否继续?",
    confirmtips: "提示",
    confirm: "确定",
    cancel: "取消",
    fixexams: "结束考试",
    now: "当前",
    notanswer: "未答",
    alreadyanswer: "已答",
    notice: "标记",
    all: "全卷共",
    net: "题",
    Countdown: "倒计时",
    preview: "上一题",
    next: "下一题",
    mark: "标记",
    handel: "交卷",
  },
  studentScore: {
    nowscore: "本次考试成绩",
    score: "分数",
    startTime: "开始时间",
    endTime: "结束时间",
  },
  examMsg: {
    startexam: "开始答题",
    stutitle: "考生须知",
    one: "不要在考试环境中离开页面或者切屏，超过三次则会自动交卷;",
    two: "请在规定的时间内进行作答，超过时间则会自动提交;",
    three: "考试结束后，记得点击交卷按钮提交试卷，确保考试成绩有效。",
  },
};

import Vue from "vue"; // 引入Vue
import VueI18n from "vue-i18n"; // 引入国际化的包
import ElementLocale from "element-ui/lib/locale";
import enLocale from "element-ui/lib/locale/lang/en"; //引入el自带得国际化语言包,防止内部插件出现乱码
import zhLocale from "element-ui/lib/locale/lang/zh-CN"; //引入el自带得国际化语言包,防止内部插件出现乱码
Vue.use(VueI18n); // 全局注册国际化包

// 准备翻译的语言环境信息
const i18n = new VueI18n({
  locale: localStorage.getItem("locale") || "en", // 从cookie中获取语言类型 获取不到就是中文
  messages: {
    zh: Object.assign(require("./zh.js")), // 中文语言包
    en: Object.assign(require("./en.js")), // 英文语言包
  },
});

// ElementLocale.i18n((key, value) => i18n.t(key, value));
export default i18n;

import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);

const router = new Router({
    mode: "history",
    routes: [
        {
            path: "/",
            name: "login", //登录界面
            component: () => import("@/components/common/login")
        },
        {
            path: "/addStudent", //添加学生
            component: () => import("@/components/student/addStudent")
        },

        {
            path: "/student",
            component: () => import("@/components/student/index"),
            children: [
                { path: "/", component: () => import("@/components/student/examMsg") },
                {
                    path: "/startExam",
                    component: () => import("@/components/student/startExam")
                },
                {
                    path: "/manager",
                    component: () => import("@/components/student/manager")
                },
                {
                    path: "/examMsg",
                    component: () => import("@/components/student/examMsg")
                },
                {
                    path: "/message",
                    component: () => import("@/components/student/message")
                },
                {
                    path: "/information",
                    component: () => import("@/components/student/information")
                },
                {
                    name: "studentScore",
                    path: "/studentScore",
                    component: () => import("@/components/student/answerScore")
                },
                {
                    path: "/scoreTable",
                    component: () => import("@/components/student/scoreTable")
                },
                {
                    path: "/historyScore",
                    component: () => import("@/components/student/historyScore")
                }
            ]
        },
        {
            path: "/answer",
            component: () => import("@/components/student/answer")
            // beforeEach: function (to, from, next) {
            //   console.log(to, from);
            //   // if (role === "2") {
            //   //   console.log("---------------");
            //   //   next({ path: "/" });
            //   //   return false;
            //   // }
            //   // next();
            // },
        }
    ]
});
router.beforeEach((to, from, next) => {
    let studentName = document.cookie.indexOf("cname") !== -1;
    let studentId = document.cookie.indexOf("cid") !== -1;
    if ((!studentName || !studentId) && to.path !== "/") {
        next({ path: "/" });
        return false;
    } else {
        next();
    }
});
export default router;

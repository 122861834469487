//中文包语言
module.exports = {
  login: {
    title: "Online Examination System",
    accountTitle: "Account Login",
    username: "Username",
    password: "Password",
    login: "Login",
    register: "Register",
    usernameplaholder: "Enter your username",
    passwordplaholder: "Enter your Password",
    emptymessage: "Please enter your username or password",
  },
  register: {
    username: "Username",
    country: "Country",
    tel: "Phone number",
    email: "Email",
    pwd: "Password",
    create: "Create",
    empty: "Reset",
    usernameplaholder: "Please enter your username",
    countryplaholder: "Please select a country",
    codemessage:'Please enter Code',
    telplaholder: "Please enter your phone number",
    emailplaholder: "Please enter your email",
    passwordplaholder: "Please enter your password",
    pwdnotempty: "Password cannot be empty",
    pwdlimuit: "Password must be at least 6 characters long",
    telnotempty: "Phone number cannot be empty",
    tellimit:
      "Please enter the correct format and the length should be between 6-14",
    emaillimit: "Please enter a valid email format",
    namenotempty: "Please enter your username",
    namelimit:
      "Please enter the correct format and the length should be between 3-10",
  },
  resetpwd: {
    email: "Email",
    emailplaholder: "Please enter your email",
    Code: "Code",
    codeplaholder: "Please enter your Code",
    password: "Password",
    surepassword: "Confirm",
  },
  header: {
    titleone: "Online examination",
    titletwo: "Practice",
    titlethree: "Score ranking",
    titlefour: "Historical scores",
    accountmanger: "Change password",
    logout: "Logout",
  },
  manger: {
    title: "Change password",
    password: "Password",
    surepassword: "Confirm",
    handel: "Submit",
    reset: "Reset",
    passempty: "Password cannot be empty",
    passwordlimit: "Password must be at least 6 characters long",
    pwdagain: "Please enter your password again",
    twopwdnotsame: "Passwords do not match!",
  },
  startexam: {
    limit: "Time limit：",
    time: "minutes",
    fulltitle: "Marks",
    searchplaholder: "Enter the paper title",
    search: "Search",
    // imputplaceholder: "",
  },
  scoreTable: {
    score: "Score",
    name: "Username",
    connect: "Tel",
    email: "Email",
    date: "Date",
  },
  answer: {
    tips: "The exam end time has not arrived yet. Do you want to submit the exam early.",
    friendlytips: "Friendly reminder",
    nowup: "Submit now",
    check: "Review",
    title: "Please select the correct option",
    deragoustips:
      "Please focus on answering the questions and refrain from any other unauthorized actions. If any occur",
    time: "time",
    many: "the test paper will be submitted automatically",
    dailogtitle: "Note",
    username: "Username",
    id: "Examination Ticket Number",
    routerMessage: "This action will exit the exam. Do you want to continue?",
    confirmtips: "Prompt",
    confirm: "Confirm",
    cancel: "Cancel",
    fixexams: "End exam",
    now: "current",
    notanswer: "Blank",
    alreadyanswer: "Answered",
    notice: "Mark",
    all: "Total：",
    net: "questions",
    Countdown: "Countdown",
    preview: "Previous",
    next: "Next",
    mark: "Mark",
    handel: "Submit",
  },
  studentScore: {
    nowscore: "Exam score",
    score: "Score",
    startTime: "Start time",
    endTime: "End time",
  },
  examMsg: {
    startexam: "Start",
    stutitle: "Instructions for Candidates",
    one: "Do not leave the exam environment or switch screens. If you do so more than three times, the exam will be automatically submitted;",
    two: "Please answer within the allotted time. If you exceed the time limit, the exam will be automatically submitted;",
    three:
      "After the exam ends, remember to click the submit button to submit your answers and ensure the validity of your exam results.",
  },
};
